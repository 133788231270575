<template>
  <div>
    <a @click="showEditModal" v-if="canUpdateRole">
      <va-icon
        name="fa fa-pencil-square-o"
        :size="21"
        class="action-icon pointer"
      />
    </a>

    <a @click="showDeleteModal = true" v-if="canDeleteRole">
      <va-icon name="fa fa-trash-o" :size="21" class="action-icon pointer" />
      <va-modal
        v-model="showDeleteModal"
        size="small"
        title="Delete role"
        :okText="$t('modal.confirm')"
        @ok="deleteRoleinDB"
      >
        Are you sure you want to delete role?
      </va-modal>
    </a>

    <va-modal
      v-model="showModal"
      size="large"
      title="Update role"
      okText="Save"
      :cancelText="$t('modal.cancel')"
      @ok="updateRoleinDB"
      @cancel="resetForm"
    >
      <p style="color: white; user-select: none;">
        There are three species of zebras: the plains zebra, the mountain zebra
        and the Grévy's zebra.
      </p>
      <div class="row">
        <div class="flex md4">
          <va-input v-model="newRole.name" label="Name" placeholder="Name" />
        </div>
        <div class="flex md7 offset--md1">
          <va-input
            v-model="newRole.description"
            label="Description"
            type="textarea"
            :minRows="3"
            :maxRows="10"
            placeholder="Role description..."
          />
        </div>
      </div>
      <div class="row">
        <div class="flex md12">
          <p class="display-6">Permissions</p>
          <br />
          <table class="role-table">
            <tr v-for="permission in permissions" :key="permission.name">
              <td>
                <b>{{ permission.name }}</b>
              </td>
              <td>
                <va-checkbox
                  v-for="access in ACCESSES"
                  :name="access.name"
                  :key="access.name"
                  v-model="permission.access[access.name]"
                  style="display: inline-block;"
                >
                  <template slot="label">
                    {{ access.name }}
                  </template>
                </va-checkbox>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </va-modal>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { isAllowed } from '../../../mixins/utils';
import { deleteRole, updateRole } from '../../../apollo/api/roles';
import { NEW_ROLE } from '../../../constants/defaultValues';

export default {
  name: 'admin-user-actions',
  props: { role: Object },
  data() {
    return {
      newRole: NEW_ROLE,
      showModal: false,
      showDeleteModal: false,
      permissions: [],
      ACCESSES: [],
    };
  },
  computed: {
    ...mapGetters(['ACCESS_CODES', 'RESOURCE_CODES']),
    canReadRole() {
      return isAllowed([this.RESOURCE_CODES.ROLE([this.ACCESS_CODES.READ])]);
    },
    canUpdateRole() {
      return isAllowed([
        this.RESOURCE_CODES.ROLE([
          this.ACCESS_CODES.READ,
          this.ACCESS_CODES.UPDATE,
        ]),
      ]);
    },
    canDeleteRole() {
      return isAllowed([this.RESOURCE_CODES.ROLE([this.ACCESS_CODES.DELETE])]);
    },
  },
  created() {
    // Set values
    this.newRole = { ...this.role };

    // Map access code in the UI
    this.ACCESSES = [];
    for (const k of Object.keys(this.ACCESS_CODES)) {
      this.ACCESSES.push({ name: k, code: this.ACCESS_CODES[k] });
    }

    // Create hashmap for resources for quick access
    const resourcePermissionHashMap = {};
    for (const k of this.role.permissions) {
      resourcePermissionHashMap[k.resource] = k.accessCode;
    }

    // Convert binary and hashmap into UI usable values
    this.permissions = [];
    for (const k of Object.keys(this.RESOURCE_CODES)) {
      const r = { name: k, access: {} };
      for (const accessKey of this.ACCESSES) {
        r.access[accessKey.name] =
          (resourcePermissionHashMap[k] & accessKey.code) === accessKey.code;
      }
      this.permissions.push(r);
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    showEditModal() {
      this.resetForm();
      this.showModal = true;
    },
    resetForm() {
      this.newUser = { ...this.role };
    },
    generatePermissionsArray() {
      const permissions = [];
      for (const p of this.permissions) {
        const newPermission = { resource: p.name, accessCode: 0 };

        for (const k of Object.keys(this.ACCESS_CODES)) {
          if (p.access[k]) {
            newPermission.accessCode =
              newPermission.accessCode | this.ACCESS_CODES[k];
          }
        }
        permissions.push(newPermission);
      }

      return permissions;
    },
    async updateRoleinDB() {
      this.setLoading(true);
      try {
        this.newRole.permissions = this.generatePermissionsArray();

        await updateRole({
          ...this.newRole,
          id: this.role.id,
        });

        this.showToast('Role updated successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        window.location.reload();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
    async deleteRoleinDB() {
      this.setLoading(true);
      try {
        await deleteRole(this.role.id);

        this.showToast('Role deleted successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        window.location.reload();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
  },
};
</script>

<style>
.role-table {
  width: 100%;
}

.role-table > td {
  padding: 5px;
  border: 1px solid #4240405d;
}
</style>
