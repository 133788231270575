import gql from 'graphql-tag';

export const QUERIES = {
  FETCH_ROLES_FOR_DROP_DOWN: gql`
    query FETCH_ROLES_FOR_DROP_DOWN($search: String) {
      rolesList(search: $search, limit: 10, page: 1) {
        roles {
          id
          name
        }
        currentPage
        pages
        total
      }
    }
  `,
  FETCH_ADMIN_ROLES: gql`
    query FETCH_ADMIN_ROLES($search: String, $limit: Int, $page: Int) {
      rolesList(search: $search, limit: $limit, page: $page) {
        roles {
          id
          name
          description
          permissions {
            resource
            accessCode
          }
          createdAt
        }
        currentPage
        pages
        total
      }
    }
  `,
};

export const MUTATIONS = {
  CREATE_ADMIN_ROLE: gql`
  mutation CREATE_ADMIN_ROLE(
    $name: String!
    $description: String
    $permissions: [PermissionInput]
  ) {
    createRole(
      roleData: {
        name: $name
        description: $description
        permissions: $permissions
      }
    ) {
      role {
        id
        name
        description
        permissions {
          resource
          accessCode
        }
        createdAt
      }
    }
  }
`,
  UPDATE_ADMIN_ROLE: gql`
    mutation UPDATE_ADMIN_ROLE(
      $id: ID!
      $name: String
      $description: String
      $permissions: [PermissionInput]
    ) {
      updateRole(
        roleData: {
          id: $id
          name: $name
          description: $description
          permissions: $permissions
        }
      ) {
        role {
          id
          name
          description
          permissions {
            resource
            accessCode
          }
          createdAt
        }
      }
    }
  `,
  DELETE_ADMIN_ROLE: gql`
    mutation DELETE_ADMIN_ROLE($roleId: ID!) {
      deleteRole(roleId: $roleId) {
        response
      }
    }
  `,
};
