<template v-if="canAddAdminUser">
  <div class="flex xs12 md3">
    <va-button @click="showModal = true">Add role</va-button>
    <va-modal
      v-model="showModal"
      title="Create role"
      size="large"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="submitNewRole"
      @cancel="resetForm"
    >
      <p style="color: white; user-select: none;">
        There are three species of zebras: the plains zebra, the mountain zebra
        and the Grévy's zebra.
      </p>
      <div class="row">
        <div class="flex md4">
          <va-input v-model="role.name" label="Name" placeholder="Name" />
        </div>
        <div class="flex md7 offset--md1">
          <va-input
            v-model="role.description"
            label="Description"
            type="textarea"
            :minRows="3"
            :maxRows="10"
            placeholder="Role description..."
          />
        </div>
      </div>

      <div class="row">
        <div class="flex md12">
          <p class="display-6">Permissions</p>
          <br />
          <table class="role-table">
            <tr v-for="permission in permissions" :key="permission.name">
              <td>
                <b>{{ permission.name }}</b>
              </td>
              <td>
                <va-checkbox
                  v-for="access in ACCESSES"
                  :name="access.name"
                  :key="access.name"
                  v-model="permission.access[access.name]"
                  style="display: inline-block;"
                >
                  <template slot="label">
                    {{ access.name }}
                  </template>
                </va-checkbox>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </va-modal>
  </div>
</template>

<script>
import { NEW_ROLE } from '../../../constants/defaultValues';
import { mapMutations, mapGetters } from 'vuex';
import { createRole } from '../../../apollo/api/roles';
import { isAllowed } from '../../../mixins/utils';

export default {
  name: 'add-user-modal',
  data() {
    return {
      confirmPassword: '',
      role: NEW_ROLE,
      ACCESSES: [],
      permissions: [],
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['ACCESS_CODES', 'RESOURCE_CODES']),
    canAddAdminUser() {
      return isAllowed([this.RESOURCE_CODES.USER([this.ACCESS_CODES.CREATE])]);
    },
  },
  watch: {
    showModal() {
      this.resetForm();
    },
  },
  created() {
    // Map access code in the UI
    this.ACCESSES = [];
    for (const k of Object.keys(this.ACCESS_CODES)) {
      this.ACCESSES.push({ name: k, code: this.ACCESS_CODES[k] });
    }

    // Convert binary and hashmap into UI usable values
    this.permissions = [];
    for (const k of Object.keys(this.RESOURCE_CODES)) {
      const r = { name: k, access: {} };
      for (const accessKey of this.ACCESSES) {
        r.access[accessKey.name] = false;
      }
      this.permissions.push(r);
    }
  },
  methods: {
    ...mapMutations(['setBackgroundLoading', 'setLoading']),
    resetForm() {
      this.user = NEW_ROLE;
    },
    generatePermissionsArray() {
      const permissions = [];
      for (const p of this.permissions) {
        const newPermission = { resource: p.name, accessCode: 0 };

        for (const k of Object.keys(this.ACCESS_CODES)) {
          if (p.access[k]) {
            newPermission.accessCode =
              newPermission.accessCode | this.ACCESS_CODES[k];
          }
        }
        permissions.push(newPermission);
      }

      return permissions;
    },
    async submitNewRole() {
      this.setLoading(true);
      try {
        this.role.permissions = this.generatePermissionsArray();
        await createRole(this.role);

        this.showToast('Role created successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        this.setLoading(false);
        window.location.reload();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
  },
};
</script>

<style>
.role-table {
  width: 100%;
}

.role-table > td {
  padding: 5px;
  border: 1px solid #4240405d;
}
</style>
