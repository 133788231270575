<template>
  <va-card>
    <div v-if="canListRoles">
      <div class="row align--center">
        <add-role-modal />
        <div class="flex xs12 md3 offset--md3">
          <va-input
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @keydown="searchRole"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>
      <loader v-if="isLoading" />
      <data-table
        :fields="fields"
        :data="roles"
        :per-page="pagination.limit"
        :total-pages="pagination.pages"
        :currentPage="pagination.currentPage"
        :api-mode="true"
        @page-selected="loadRoles"
        v-else
      >
        <template slot="createdAt" slot-scope="props">
          {{ props.rowData.createdAt | validDateFormat }}
        </template>
        <template slot="actions" slot-scope="props">
          <role-actions :role="props.rowData" />
        </template>
      </data-table>
    </div>
    <access-denied v-else />
  </va-card>
</template>

<script>
import { fetchRoles } from '../../../apollo/api/roles';
import { mapGetters, mapMutations } from 'vuex';
import { isAllowed } from '../../../mixins/utils';
import { validDateFormat } from '../../../mixins/filters';
import { ROLE_TABLE_FIELDS } from '../../../constants/tableFields';
import DataTable from '../../../components/DataTable';
import AccessDenied from '../../../components/AccessDenied.vue';
import AddRoleModal from './AddRoleModal.vue';
import Loader from '../../../components/Loader';
import RoleActions from './RoleActions.vue';

export default {
  components: {
    DataTable,
    Loader,
    AccessDenied,
    RoleActions,
    AddRoleModal,
  },
  data() {
    return {
      term: '',
      perPage: '20',
      perPageOptions: ['20', '30', '40', '50'],
      roles: [],
      pagination: {
        limit: 20,
        currentPage: 1,
        pages: 0,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'ACCESS_CODES', 'RESOURCE_CODES']),
    fields() {
      return ROLE_TABLE_FIELDS;
    },
    canListRoles() {
      return isAllowed([this.RESOURCE_CODES.ROLE([this.ACCESS_CODES.LIST])]);
    },
  },
  filters: {
    validDateFormat,
  },
  watch: {
    perPage: function (newVal) {
      this.pagination.limit = parseInt(newVal);
      this.loadRoles();
    },
    term: function (newVal, oldVal) {
      if (oldVal.length > 0 && newVal.length === 0) {
        this.loadRoles();
      }
    },
  },
  async mounted() {
    await this.loadRoles(1);
  },
  methods: {
    ...mapMutations(['setLoading']),
    async loadRoles(page = 1) {
      this.setLoading(true);
      try {
        const { rolesList } = await fetchRoles(
          this.term,
          this.pagination.limit,
          page
        );

        this.roles = rolesList.roles;
        this.pagination = {
          ...this.pagination,
          currentPage: rolesList.currentPage,
          pages: rolesList.pages,
          total: rolesList.total,
        };
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
    async searchRole(e) {
      if (e.key === 'Enter') {
        await this.loadRoles(1);
      }
    },
  },
};
</script>

<style lang="scss">
.action-icon {
  margin: 5px;
  color: 'black';
}
</style>
